import React, { FC } from 'react';
import Slider, { Settings } from 'react-slick';
import { SwiperOptions } from 'swiper';
import Subtitle, { SubtitleProps } from 'components/commercetools-ui/atoms/subtitle';
import Typography from 'components/commercetools-ui/atoms/typography';
import Wrapper from 'components/HOC/wrapper';
import { desktop, mobile } from 'helpers/utils/screensizes';
import { Reference } from 'types/reference';
import styles from './ProductSlider.module.scss';
import { TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';
import Link from '../../content/link';
import Tile from '../product-tile';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sliderSettings: Settings = {
  dots: false,
  infinite: false,
  speed: 500,
  centerMode: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: desktop - 1,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: mobile - 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        centerPadding: '20%',
        rows: 1,
      },
    },
  ],
};

const titleClassName = {
  xs: 'md:text-16',
  sm: 'md:text-18 lg:text-22',
  lg: 'md:text-22 lg:text-28',
};

export interface ProductSliderProps extends Partial<SwiperOptions> {
  products: TNikonProduct[];
  title: string;
  titleVariant?: 'xs' | 'sm' | 'lg';
  subline?: string;
  subtitleVariant?: SubtitleProps['variant'];
  ctaLabel?: string;
  ctaLink?: Reference;
  clearDefaultWrapperStyles?: boolean;
  slidesPerView?: number;
  disableProductQuickView?: boolean;
  disableProductWishlistButton?: boolean;
  disableProductVariants?: boolean;
  innerArrows?: boolean;
  solidArrows?: boolean;
  onAddToCart?: (product: TNikonProduct, variant: TNikonVariant, count: number) => void;
  onProductClick?: (product: TNikonProduct) => void;
  classNames?: {
    title?: string;
  };
  variant?: 'normal' | 'cart';
}

const ProductSlider: FC<ProductSliderProps> = ({
  products,
  title,
  subline,
  ctaLabel,
  ctaLink,
  onProductClick,
  titleVariant = 'lg',
  subtitleVariant = 'lg',
  clearDefaultWrapperStyles = false,
  disableProductQuickView = false,
  disableProductWishlistButton = false,
  disableProductVariants = false,
  classNames = {},
  onAddToCart,
}) => {
  return (
    <Wrapper clearDefaultStyles={clearDefaultWrapperStyles} className="bg-white">
      <div>
        <Typography
          className={`mb-12 ${titleClassName[titleVariant]} ${classNames.title ?? ''} ${styles.productSliderTitle}`}
          fontSize={20}
          as="h3"
        >
          {title}
        </Typography>
        {(subline || ctaLink) && (
          <div className="mt-8 flex items-center justify-between md:mt-16 lg:mt-14">
            {subline && <Subtitle subtitle={subline} variant={subtitleVariant} />}
            {ctaLink && (
              <div className="hidden lg:block">
                <Link target={ctaLink} withArrow>
                  <span className="font-medium leading-[24px] text-secondary-black">{ctaLabel}</span>
                </Link>
              </div>
            )}
          </div>
        )}
        {ctaLink && (
          <div className="mt-20 block md:mt-16 lg:hidden">
            <Link target={ctaLink} withArrow>
              <span className="font-medium leading-[24px] text-secondary-black">{ctaLabel}</span>
            </Link>
          </div>
        )}
      </div>
      <div className="mt-20 md:mt-24 lg:mt-20">
        <div className={`relative mt-6 w-full ${styles.sliderContainer}`}>
          <Slider {...sliderSettings}>
            {products.map((product, index) => (
              <Tile
                key={`${product.productId}-related-product`}
                disableQuickView={disableProductQuickView}
                disableWishlistButton={disableProductWishlistButton}
                disableVariants={disableProductVariants}
                product={product as unknown as TNikonProduct}
                onAddToCart={onAddToCart}
                onClick={() => {
                  onProductClick?.(product);
                }}
              />
            ))}
          </Slider>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductSlider;
