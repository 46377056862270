import React, { FC, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Button from 'components/commercetools-ui/atoms/button';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { desktop } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import styles from './ProductTile.module.scss';
import { buttonTextConfig } from './types/buttonTextConfig';
import { getVariantAddToCartLimit, getVariantCartQuantity } from '../../../../../helpers/productHelpers';
import { getLocalizationInfo } from '../../../../../project.config';
import { TLocalizationInfo } from '../../../../../types/TLocalizationInfo';
import { EAddToCartStatus, TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';

interface ProductTileProps {
  product: TNikonProduct;
  onClick?: () => void;
  isSearchResult?: boolean;
  disableQuickView?: boolean;
  disableWishlistButton?: boolean;
  disableVariants?: boolean;
  onAddToCart?: (product: TNikonProduct, variant: TNikonVariant, count: number) => void;
}

const ProductTile: FC<ProductTileProps> = ({
  product,
  isSearchResult = false,
  disableVariants = false,
  onAddToCart,
}) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isDesktopSize] = useMediaQuery(desktop);
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addItem, data: cartData } = useCart();
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale || router.defaultLocale);
  const [selectedVariant, setSelectedVariant] = useState<TNikonVariant>(
    (useVariantWithDiscount(product.variants) as TNikonVariant) ?? product?.variants?.[0],
  );
  const variantCartQuantity = getVariantCartQuantity({ cart: cartData, sku: selectedVariant.sku });
  const variantAddToCartLimit = getVariantAddToCartLimit(selectedVariant, localizationInfo.appLocale);
  const userCanAddMoreUnitsToCart = variantCartQuantity < variantAddToCartLimit;
  const userCanBuy =
    !Boolean(product.isArchived) && selectedVariant.attributes.isAvailableForSale && userCanAddMoreUnitsToCart;
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);

  const addToCartStatus =
    (selectedVariant.addToCartStatus as unknown as EAddToCartStatus) || EAddToCartStatus.ADD_TO_CART;

  const handleAddToCart = () => {
    if (userCanBuy) {
      setLoading(true);

      addItem(selectedVariant, 1).then(() => {
        setLoading(false);
        setAdded(true);

        setTimeout(() => {
          setAdded(false);
        }, 5000);

        if (onAddToCart) {
          onAddToCart(product, selectedVariant, 1);
        }
      });
    }
  };

  const productUrl = useMemo(() => {
    if (!product._url) {
      return '#';
    }

    if (isSearchResult) {
      return `${product._url}?sr=1`;
    }

    return product._url;
  }, [product._url, isSearchResult]);

  return (
    <div className={`${styles.tileBody} ${isDesktopSize && styles.hoverBody} ${added && styles.borderAdd}`}>
      <div className="relative">
        <NextLink href={productUrl}>
          <a>
            <div className="relative w-full">
              <div className="relative bg-white p-8 md:p-16">
                <div className="relative block w-full" style={{ paddingBottom: '122%' }}>
                  <Image
                    src={selectedVariant.images?.[0] || imageUnavailable}
                    onError={({ currentTarget }) => {
                      currentTarget.src = imageUnavailable;
                    }}
                    suffix="medium"
                    alt={product.name}
                    objectFit="contain"
                    objectPosition="center"
                    className="w-full rounded-sm group-hover:opacity-75 md:p-16"
                  />
                </div>
              </div>
            </div>
          </a>
        </NextLink>
      </div>
      <div>
        <NextLink href={productUrl}>
          <a className="text-decoration-none text-black">
            <div>
              <div className=" t block max-w-[80%] overflow-hidden text-ellipsis whitespace-pre ">{product?.name}</div>
              {!disableVariants && (
                <div className=" flex flex-wrap items-center gap-4 md:mt-12">
                  {product?.variants.map((variant, index) => (
                    <span
                      key={index}
                      className={`block cursor-pointer rounded-full border p-[6px] ${
                        variant.sku !== selectedVariant.sku ? 'border-neutral-300' : 'border-neutral-500'
                      }`}
                      style={{ backgroundColor: variant.attributes?.color || variant.attributes?.finish }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedVariant(variant as TNikonVariant);
                      }}
                    ></span>
                  ))}
                </div>
              )}
              <div>
                <p className={styles.currentPrice}>
                  {CurrencyHelpers.formatForCurrency(selectedVariant.price, router.locale)}
                </p>

                {addToCartStatus !== EAddToCartStatus.OUT_OF_STOCK && !userCanAddMoreUnitsToCart && (
                  <p className={styles['max-quantity-meet']}>
                    {formatProductMessage({
                      id: 'maxQuantityMeet',
                      defaultMessage: 'Maximum order quantity has been met.',
                    })}
                  </p>
                )}
              </div>
            </div>
          </a>
        </NextLink>
      </div>
      <div className={styles.addToCart}>
        <Button
          data-testid="button"
          size="xs"
          className={`${added && 'btn-green'} btn-yellow `}
          variant="primary"
          loading={loading}
          disabled={addToCartStatus === EAddToCartStatus.OUT_OF_STOCK || loading || !userCanAddMoreUnitsToCart}
          onClick={() => !added && handleAddToCart()}
        >
          {!added && formatCartMessage(buttonTextConfig[addToCartStatus as keyof typeof buttonTextConfig])}
          {added && formatCartMessage({ id: 'cart.itemAdded', defaultMessage: 'Item added' })}
        </Button>
      </div>
    </div>
  );
};

export default ProductTile;
